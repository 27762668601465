import { Injectable } from '@angular/core';
import { RequestService } from "./request.service";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserService extends RequestService {

  readonly url = environment.server;

  /**
   * Solicita al servicio, el login
   * @param email Usuario email
   * @param password Contraseña del usuario
   */
  public doLogin(email:string, password:string): Promise<any>  {
    const body = {
      'email': email,
      'password': password,
    };

    return this.request('POST', this.url + 'sign_in/', body, false);
  }

  /**
   * Recuperar contraseña
   * @param email Email del usuario
   */
  public recoverPwd(email:string): Promise<any>  {
    const body = {
      'email': email
    };

    return this.request('POST', this.url + 'reset_password/', body, false);
  }

  /**
   * Cambiamos la contraseña
   * @param body Cuerpo que envía los valores de cambio de contraseña
   */
  public changePwd(body): Promise <any> {
    return this.request('POST', this.url + 'user/change/password/', body);
  }

  /**
   * Registro de usuario nuevo
   * @param email Correo
   * @param password Contraseña
   */
  public registrarUsuario(email, password, promo_code)  {
    const body = {
      email,
      password,
      promo_code
    };

    return this.request('POST', this.url + 'sign_up/', body, false);
  }

  /**
   * Reenviar un código de verificación
   */
  public resendCodeVerification() {
    return this.request('POST', this.url + 'email/resend_code/');
  }

  /**
   * Solicitamos la verificación de código que enviamos
   * @param codigo Código
   */
  public verificarCodigo(codigo)  {
    const body = {
      code: codigo
    };

    return this.request('POST', this.url + 'email/verify/', body);
  }

  /**
   * Obtenemos la lista de servicios que tiene el usuario registrado
   */
  public getSubscription()  {
    return this.request('GET', this.url + 'my_subscription/');
  }

  /**
   * Registramos una suscripción al usuario
   * @param plan_id Id del plan seleccionado
   */
  public registerSubscription(plan_id) {
    const body = {
      plan: plan_id
    };

    return this.request('POST', this.url + 'my_subscription/', body);
  }

  /**
   * Se solicita el cambio de plan del usuario
   * @param plan_id Id del plan seleccionado
   */
  public updateSubscription(plan_id) {
    const body = {
      plan: plan_id
    };

    return this.request('PUT', this.url + 'my_subscription/', body);
  }

  /**
   * Agregamos una tarjeta registrada en conekta al usuario
   * @param token Token devuelto por Conekta
   */
  public addCardToUser(token: string)  {
    const body =  {
      type: "card",
      token_id: token
    }
    return this.request('POST', this.url + 'payment_methods/', body);
  }

  /**
   * Consulta la lista de tarjetas registradas para pago desde conekta al usuario
   */
  public getCardsFromUser() {
    return this.request('GET', this.url + 'payment_methods/');
  }

  /**
   * Removemos una tarjeta de un usuario
   * @param card Id de la tarjeta
   */
  public removeCardFromUser(card) {
    return this.request('DELETE', this.url + 'payment_methods/' + card);
  }

  /**
   * Cambiamos el método de pago para que quede por defecto
   * @param id_card Id de la tarjeta a quedar por defecto
   */
  public changeDefaultPaymentMethod(id_card) {
    const body =  {
      default_payment_source_id: id_card
    }
    return this.request('PUT', this.url + 'default_payment_method/', body);
  }

  /**
   * Obtiene la lista de RFC para llenar la tabla del dashboard
   */
  public getRFCList() {
    return this.request('GET', this.url + 'my_rfcs_list/');
  }

  /**
   * Registro de un nuevo RFC para el usuario
   * @param rfc Objeto con la información del RFC
   */
  public postRFC(rfc)  {
    const body =  [rfc];
    return this.request('POST', this.url + 'my_rfcs_list/', body);
  }

  /**
   * Editar un RFC para el usuario
   * @param rfc Objeto con la información del RFC
   */
  public editRFC(rfc)  {
    const body =  rfc;
    return this.request('PUT', this.url + `my_rfcs_list/${rfc.id}/`, body);
  }

  /**
   * Eliminamos un RFC del usuario
   * @param rfc Objeto con la información del RFC
   */
  public deleteRFC(rfc)  {
    return this.request('DELETE', this.url + `my_rfcs_list/${rfc.id}/`);
  }

  /**
   * Solicita la descarga de las opiniones de cumplimiento de los RFC seleccionados
   * @param rfcList Lista de IDs de RFC a descargar
   */
  public triggerDownload(rfcList) {
    const body = {
      rfc_ids_arr: rfcList
    };

    return this.request('POST', this.url + 'oc_service/', body);
  }

  /**
   * Preguntamos si hay algun proceso actualmente de descarga de opiniones
   */
  public getStatusDownloads() {
    return this.request('GET', this.url + 'oc_service/');
  }

  /**
   * Programamos un proceso de descarga para la fecha seleccionada
   * @param date Fecha para que se genere
   * @param rfcList Lista de RFC a verificar
   */
  public scheduleProcess(date, rfcList)  {
    const body = {
      "action": "schedule",
      "datetime": date,
      "rfc_ids_arr": rfcList
    };

    return this.request('PUT', this.url + 'oc_service/', body);
  }

  /**
   * Preguntamos si hay procesos actualmente
   */
  public getScheduledProcess()  {
    return this.request('GET', this.url + 'my_scheduled_jobs/');
  }

  /**
   * PUT
   * Solicitamos quitar la programación de opiniones
   */
  public unschedule() {
    const body = {
      "action": "unschedule"
    };
    return this.request('PUT', this.url + 'oc_service/', body);
  }

  /**
   * Función que envía una solicitud de ayuda de tipo funcional para la aplicación
   * @param text Descripción del comentario
   * @returns Promise
   */
   public postFeedback(text)  {
    const body = {
      "content": "text"
    };
    return this.request('POST', this.url + 'ayuda/', body);
  }

  /**
   * Obtiene el link para descargar un excel con la lista de RFC registrados en la cuenta
   * @returns @async Promise<String>
   */
  public getDownloadLink() {
    return this.request('GET', this.url + 'get_rfc_excel/');
  }

  /**
   * Actualiza la dirección y el RFC de la cuenta para generación de facturas
   * @param rfc RC
   * @param address Dirección
   * @returns Promise
   */
  public updateRFC(rfc, address) {
    const body = {
      "extended_rfc": rfc,
      "extended_address": address
    };
    return this.request('PUT', this.url + 'my_account/', body);
  }

  /**
   * Actualizamos la información de la cuenta
   * @param body Cuerpo de la cuenta
   * @returns Promise
   */
  public updateAccount(body) {
    return this.request('PUT', this.url + 'my_account/', body);
  }

  /**
   * Información de la cuenta: RFC, Dirección, etc
   * @returns Promise
   */
  public getAccountInfo() {
    return this.request('GET', this.url + 'my_account/');
  }

  /**
   * Cancelación de la suscripción
   */
  public postCancelSuscription()  {
    const body = {};
    return this.request('POST', this.url + 'my_subscription/cancel/', body);
  }

  public tryPayment() {
    const body = {};
    return this.request('POST', this.url + 'my_subscription/resume/', body);
  }
}