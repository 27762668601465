import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login', 
    loadChildren: () => import('src/app/components/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'recuperacontra',
    loadChildren: () => import('src/app/components/recuperacontra/recuperacontra.module').then(m => m.RecuperacontraModule)
  },
  {
    path: 'registro',
    loadChildren: () => import('src/app/components/registro/registro.module').then(m => m.RegistroModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('src/app/components/dashboard/dashboard.module').then(m => m.DashboardModule)
  }
];

@NgModule({
    imports: [
      FlexLayoutModule,
      RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class LayoutRoutingModule { }
