import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';


import { AppModule } from '../app.module';
import { LocalstorageService } from './localstorage.service';

@Injectable()
export class RequestService {

  public _http: HttpClient;

  private _router: Router;

  constructor(private _ls: LocalstorageService) {
    this._http = AppModule.injector.get(HttpClient) as HttpClient;
    this._router = AppModule.injector.get(Router) as Router;
  }

  /**
   * Función que hace request
   * @param type Tipo, ejemplo: GET, POST
   * @param url Url de la petición
   * @param _body Lo que vas a enviar cuando es "POST"
   * @param setToken Opcional, envía o no, un token
   */
  protected request(type: string, url: string, _body?: any, setToken = true): Promise<any>  {
    return new Promise((resolve, reject) => {
      let token = '';

      let _headers = new HttpHeaders({
        'Content-Type':  'application/json'
      });

      if(setToken)  {
        token = "Bearer " + this._ls.getToken();
        _headers = new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': token
        });
      }

      // const headers = new Headers({
      //   Authorization: token
      // });

      const options = {
        headers: _headers,
        observe: 'response' as 'response'
      };

      let _subscriptionRequest;

      switch (type) {
        case 'POST':
          _subscriptionRequest = this._http.post(url, _body, options);
          break;
        case 'GET':
          _subscriptionRequest = this._http.get(url, options);
          break;
        case 'PUT':
          _subscriptionRequest = this._http.put(url, _body, options);
          break;
        case 'DELETE':
          _subscriptionRequest = this._http.delete(url, options);
          break;
        default:
          return null;
      }

      _subscriptionRequest.subscribe(
        (data: any) => {
        //   const rbody = data._body;

        //   // Validamos la estructura de la respuesta
        //   const response = this.checkResponse(rbody, data.statusText);
        //   if (response.info.error)  {
        //     reject(response.info.message);
        //     if (response.info.message === 'Err: Token') {
        //       this._router.navigate(['login']);
        //     }
        //     return;
        //   }
          resolve(data);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  protected checkResponse(body: any, statusText: string): any {
    if (statusText !== 'OK')  {
      return {
        info: {
          error: true,
          message: 'Ocurrió un error de acceso al servidor, notificar al administrador'
        },
        data: null
      };
    }

    try {
      const _cuerpo = JSON.parse(body);
      const cuerpo = _cuerpo;
      return cuerpo;
    } catch (error) {
      return {
        info: {
          error: true,
          message: 'Ocurrió un error de acceso al servidor, notificar al administrador'
        },
        data: null
      };
    }
  }


}
